@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

/*Global*/
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #edeef3 !important;
}

.pointer {
  cursor: pointer;
}

#lead_notes_form {
  padding: 5px 10px;
}

#close-conversation-btn:disabled {
  opacity: 0.5;
}

#lead_notes_form textarea {
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

#stat-range-chooser {
  display: none;
}

#stat-from-date,
#stat-to-date {
  margin: 2px;
  max-width: 50%;
  padding: 3px 15px;
  border-radius: 5rem;
}

.home-stat-duration-filter-container {
  display: flex;
  justify-content: right;
  padding-bottom: 10px;
  padding-right: 10px;
}

#home-stat-duration-filter {
  padding: 3px 15px;
  border-radius: 5rem;
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
}

.transfer-modal {
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  width: 360px;
  min-height: 530px;
  background-color: #262c3e;
  color: #ccffff;
  padding: 10px 20px;
  bottom: 65px;
  border: 1px solid #322f29;
}

.lead-action-btn {
  background-color: rgba(0, 0, 0, 0);
  margin-top: 2px;
  margin-left: 5px;
  line-height: 30px !important;
  text-align: center;
}

.lead-transfer-btn {
  background-color: rgba(0, 0, 0, 0);
  margin-top: 2px;
  margin-left: 5px;
}

.claim-btn:after {
  content: "Claim";
}

.callback-btns::after {
  content: "Callback";
}

.callstat-numbers .item {
  width: 31% !important;
}

.mark-spam-btn:after {
  content: "Spam?";
}

.transfer-btn:after {
  content: "Transfer";
}

.inprogress:after {
  content: "Wait...";
}

a:hover {
  text-decoration: none;
}

.hide {
  display: none !important;
}

.dialer {
  padding-top: 10px;
}

.dialer-via {
  align-items: center;
}

#dialer-call-btn {
  background-color: green;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  padding: 10px;
  margin-bottom: 10px;
}

#dialer-call-btn:disabled {
  opacity: 0.5;
}

.dialer div {
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  margin-bottom: 16px;
}

.dialer div span {
  display: block;
  font-size: 13px;
}

.error {
  color: red;
  margin: 10px 0px;
}

/* .leads-tabs,
.mh {
  min-height: 568px;
} */

.lead-nav {
  cursor: pointer;
}

.btn-main {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #009fad;
  width: 100%;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  outline: none;
  -moz-appearance: none;
  border: 0;
  border-radius: 22px;
  transition: 0.3s;
  color: white;
}
.btn-main:hover {
  background-color: #12c197;
}

.review-start {
  font-size: 20px !important;
  cursor: pointer;
  color: #ccffff;
}

.review-start:hover,
.checked {
  color: orange;
}

.bottom-bar-padding {
  padding-bottom: 100px;
}

.fixed-bar {
  position: fixed;
  z-index: 99;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

#fullscreen-modal {
  width: 360px;
}

.chat-Log .message {
  background-color: orange !important;
  padding: 1px 12px !important;
}

#loader {
  position: fixed;
  margin: auto;
  left: 48%;
  top: 50%;
  text-align: center;
  display: none;
  /* padding-top: 100px; */
}

#loader img {
  width: 70px;
}

.btn-main:disabled {
  opacity: 0.5;
}

.btn-main:disabled {
  content: "Wait";
}

.login-btn:before {
  content: "Sign In";
}

.login-btn:disabled:before {
  content: "Please wait...";
}

.btn-main:hover {
  transition: 0.3s;
  text-decoration: none;
  color: white;
}

.popup {
  min-width: 360px;
  max-width: 800px;
  height: 85vh;
}

.double-section {
  display: flex;
}

.full-screen-page {
  width: 800px;
}

/*Login*/

.login {
  width: 360px;
  background-color: #262b3f;
  padding: 50px 40px;
}

.login .logo {
  margin-bottom: 50px;
}
 .or {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 6px;
   max-width: 100%;

 }

 .or:before {
   content: "";
   position: absolute;
   top: 50%;
   right: 0;
   left: 0;
   height: 2px;
   background-color: #939393;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
 }

 .or-text {
   z-index: 1;
   padding: 0 8px;
   text-transform: uppercase;
   background-color: #f2f2f2;
 }
.login .logo img {
  height: 45px;
}
.google-button div{

    border-radius: 33px;
  
}
.login h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  color: #ffffff;
  margin-bottom: 30px;
}

.login p {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  margin-bottom: 24px;
  color: #a4abc4;
}

.login form {
  width: 100%;
}

.login form label {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 13px;
  color: #a4abc4;
}

.login form label input {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: 1px solid #656b82;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  margin-top: 5px;
  padding: 0 12px;
  outline: none;
  -moz-appearance: none;
  color: #ffffff;
}

.login form .forgot {
  text-align: right;
  margin-bottom: 24px;
}

.login form .forgot a {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  text-align: right;
  color: #a4abc4;
}

.login .btn-main {
  margin-bottom: 24px;
}

.login .login-google a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background-color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-radius: 22px;
  color: #262b3f;
}

.login .login-google svg {
  margin-right: 10px;
  height: 24px;
}

/*Home*/

.home {
  width: 360px;
  height: calc(100vh - 138px);
  background-color: #171b26;
}

.home-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #262b3f;
  padding: 12px 15px;
  position: sticky;
  top: 0;
  z-index: 9;
}

.home-top .logo img {
  height: 24px;
}

.home-top .actions {
  display: flex;
  align-items: flex-start;
}

.home-top .actions .availability {
  cursor: pointer;
}

.home-top .actions .availability .custom-switch {
  width: 44px;
  height: 22px;
  min-height: auto;
  padding-left: 0;
  position: relative;
}

.home-top .actions .availability .custom-control-label::before {
  width: 44px;
  height: 22px;
  background-color: #a4abc4;
  border-radius: 20px;
  left: 0;
  top: 0;
  box-shadow: none;
  border: 0;
}

.settings-list input {
  padding: 5px 10px;
  border-radius: 5px;
}

.home-top
  .actions
  .availability
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #12c197;
}

.home-top .actions .availability .custom-control-label::after {
  background-color: #ffffff;
  width: 16px;
  height: 16px;
  left: 6px;
  top: 3px;
}

.home-top
  .actions
  .availability
  .custom-control-input:checked
  ~ .custom-control-label::after {
  left: 12px;
}

.home-top .actions .notifications,
.home-top .actions .settings {
  margin-left: 15px;
  position: relative;
}

.home-top .actions .notifications .badge {
  height: 12px;
  width: 12px;
  position: absolute;
  right: -6px;
  bottom: -2px;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ea829b;
  padding: 3px;
  border-radius: 50%;
  color: #ffffff;
}

/* dialer */
.call-dialer {
  height: 100%;
  flex-direction: column;
  padding: 10px;
  display: flex;
  color: #fff;
}
.call-dialer h4 {
  margin-bottom: 5px;
}
.dialer-icon {
  justify-content: center;
  margin: 50px 0 0;
  padding: 0;
}

.dialer-icon li.active span svg {
  fill: #000;
}
.dialer-icon li.active span {
  background-color: #fff;
}
.dialer-icon li p {
  margin: 0;
}
.dialer-icon li svg {
  height: 25px;
}
.dialer-icon li span {
  display: block;
  padding: 20px;
  margin-bottom: 5px;
  border-radius: 50%;
  background-color: #ffffff4d;
}
.dialer-icon li {
  text-align: center;
  list-style: none;
  margin: 0 10px;
}
.call-dialer .dialer-call-button span {
  display: inline-block;
  padding: 20px;
  border-radius: 50%;
  background-color: #ffffff4d;
}
.call-dialer .dialer-call-button {
  margin-top: auto;
}
.dialer-call-button svg {
  height: 25px;
}
.call-dialer #dialer-call-btn.exit-call svg {
  height: 30px;
  width: 30px;
}
.call-dialer #dialer-call-btn.exit-call {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  transform: rotate(133deg);
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.msg-outgoing:after,
.msg-undefined::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -13px;
  margin-left: -10px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent transparent blue;
}

.chat-Log .message::after {
  border-color: transparent transparent transparent orange !important;
}

.msg-outgoing,
.msg-undefined {
  width: 80%;
  margin-left: 20%;
  background-color: #22225e !important;
}

.msg-note {
  width: 80%;
  margin-left: 20%;
  background-color: rgb(136 131 86) !important;
}

.msg-outgoing .time,
.msg-undefined .time {
  justify-content: end !important;
}

.msg-incoming,
.msg-undefined {
  width: 70%;
}

.msg-incoming:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -3px;
  margin-left: -10px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent #262b3f transparent transparent;
}

.conversation-item {
  cursor: pointer;
}

#add-messge-btn,
#close-conversation-btn {
  padding: 2px 5px;
  font-size: 14px;
  background-color: green;
  border-radius: 2px;
  color: #ffffff;
}

#close-conversation-btn {
  background-color: red !important;
  margin-right: -1px;
}

.container-incoming {
  flex-direction: row-reverse;
  justify-content: start;
}

.unread-badge {
  position: absolute;
  right: 45px;
  top: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ea829b;
  padding: 5px 10px;
  border-radius: 5px;
  color: #ffffff;
}

.delete-badge {
  position: absolute;
  right: 2px;
  top: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #ea829b; */
  padding: 5px 10px;
  border-radius: 5px;
}

.delete-badge svg {
  stroke: red;
}

.home-main {
  padding: 15px 15px 7px 15px;
}

.home-main h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 160%;
  color: #ffffff;
}

.home-main p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 24px;
  color: #a4abc4;
}

.bottom-bar .current {
  color: #ffffff !important;
  font-weight: 900;
}

.msg-incoming .chat-status-mark {
  display: none !important;
}

.chat-status-mark {
  margin-right: 4px;
}

.bottom-bar .current span {
  border-bottom: 1px solid white;
}

.end {
  justify-content: end !important;
}

.status-badge {
  position: absolute;
  right: 5px;
  top: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  color: #ffffff;
}

.status-assigned {
  right: 5px;
  top: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  color: #ffffff;
}

.home-numbers {
  display: flex;
  justify-content: space-around;
  /* flex-wrap: wrap; */
}

.home-numbers .item:last-child {
  margin-right: 0px;
}
.home-numbers .item {
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #262b3f;
  border-radius: 12px;
  margin-bottom: 8px;
  padding: 15px;
  margin-right: 5px;
}

#notification-list {
  min-height: 325px;
}

.lead-numbers {
  padding: 5px;
}

.lead-numbers .item {
  width: auto;
}

.home-numbers .item .icon {
  height: 40px;
  margin-bottom: 4px;
}

[data-read="0"] {
  border: 1px solid green !important;
}

.home-numbers .item .number {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 4px;
  color: #ffffff;
}

.home-numbers .item h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  margin: 0;
  color: #a4abc4;
}

.home-requests {
  padding: 7px 15px 15px 15px;
  min-height: 325px;
}

.home-requests h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 200%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-bottom: 24px;
  color: #ffffff;
}

.home-requests .empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  padding-top: 50px;
  color: #a4abc4;
}

.home-requests .empty svg {
  margin-bottom: 20px;
}

.home-requests .requests-wrapper .item {
  background: linear-gradient(266.6deg, #12c197 0%, #009fad 100%);
  border-radius: 4px;
  padding: 12px 15px;
  margin-bottom: 12px;
}

.home-requests .requests-wrapper .item .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.home-requests .requests-wrapper .item .top .title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
}

.home-requests .requests-wrapper .item .top .title svg {
  margin-right: 15px;
}

.home-requests .requests-wrapper .item .top .time {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 140%;
  text-align: right;
  color: #ffffff;
}

.home-requests .requests-wrapper .item .list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 30px;
}

.home-requests .requests-wrapper .item .list .flex {
  width: 50%;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 2px;
  color: #ffffff;
}

.dayoffswitch {
  margin-right: 15px;
}

.lead-info {
  color: #ccffff;
  text-align: left;
  justify-content: flex-start !important;
}

.lead-info label {
  margin-bottom: 6px;
}

.lead-info p {
  margin-bottom: 0;
  padding-left: 60px;
  font-size: 15px;
  max-width: 50%;
}

.home-requests .requests-wrapper .item .list .flex a {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
}

.home-requests .requests-wrapper .item .list .flex svg {
  margin-right: 8px;
}

.bottom-bar {
  display: flex;
  background-color: #edeef3;
}

.bottom-bar .bar {
  width: 360px;
  padding: 12px 15px 10px 15px;
  background-color: #262b3f;
}

.bottom-bar .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33%;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  cursor: pointer;
  position: relative;
  color: #a4abc4;
}

.bottom-bar .item svg {
  height: 26px;
  margin-bottom: 7px;
}

.bottom-bar .item.current svg path {
  fill: #009fad;
}

/*Leads*/

.leads {
  width: 360px;
  background-color: #171b26;
  min-height: calc(100vh - 70px);
}

.leads-tabs .nav {
  background: #262b3f;
  border-bottom: 0;
  margin-bottom: 20px;
  position: sticky;
  top: 70px;
  z-index: 9;
}

.leads-tabs .nav .nav-item {
  width: 20%;
}

.leads-tabs .nav .nav-item .nav-link {
  padding: 12px 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 200%;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  color: #a4abc4;
}

.leads-tabs .nav .nav-item .nav-link:hover {
  border: 0;
}

.leads-tabs .nav .nav-item .nav-link.active {
  background-color: transparent;
  color: white;
  border-bottom: 3px solid #009fad;
}

.leads-list {
  padding: 13px 15px;
}

.leads-list .item {
  padding: 15px 12px;
  background: #262b3f;
  border-radius: 4px;
  margin-bottom: 12px;
  position: relative;
  
}
.leads-list .item:hover{
  background-color: #3a405a;
}
.leads-list .item .company {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 9px;
  color: #ffffff;
}

.leads-list .item .company .tag {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5ab795;
  border-radius: 8px;
  padding: 2px 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 120%;
  text-transform: uppercase;
  margin-left: 4px;
  color: #ffffff;
}

.leads-list .item .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leads-list .item .flex .avatar {
  margin-left: 5px;
}

.leads-list .item .flex .avatar img {
  width: 30px;
}

.leads-list .item .flex .list {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: space-between;
}

.leads-list .item .flex .list .list-item {
  display: flex;
  margin-right: 0px;
  align-items: center;
  /* width: 50%; */
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  margin-bottom: 3px;
  color: #ffffff;
}

.leads-list .item .flex .list .list-item a {
  margin-right: -35px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  margin-bottom: 3px;
  color: #a4abc5;
  overflow: hidden;
}

.leads-list .item .flex .list .list-item.scheduled {
  color: #e99f7e;
}

.leads-list .item .flex .list .list-item.missed {
  color: #ea819b;
}

.leads-list .item .flex .list .list-item svg {
  margin-right: 7px;
}

/*Meetings*/

.meetings {
  background-color: #171b26;
}

.meetings-tabs {
}

.meetings-tabs .nav {
  background: #262b3f;
  border-bottom: 0;
  margin-bottom: 20px;
}

.meetings-tabs .nav .nav-item {
  width: 50%;
}

.meetings-tabs .nav .nav-item .nav-link {
  padding: 12px 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 200%;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  border: 0;
  color: #a4abc4;
}

.meetings-tabs .nav .nav-item .nav-link:hover {
  border: 0;
}

.meetings-tabs .nav .nav-item .nav-link.active {
  background-color: transparent;
  color: white;
  border-bottom: 3px solid #009fad;
}

.meetings-tabs .tab-content .schedule-meeting {
  width: 100%;
  padding: 0 15px;
  margin-bottom: 20px;
}

.tab-content {
  min-height: 450px;
}

.meetings-tabs .tab-content .schedule-meeting a {
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #009fad;
  border-radius: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
}

#signout-button {
  margin-top: 20px;
  margin-left: 15px;
  width: 100px;
}

.meetings-tabs .tab-content .schedule-meeting svg {
  margin-right: 14px;
}

.meetings-tabs .tab-content .meetings-list {
  padding: 10px 15px;
}

.meetings-tabs .tab-content .meetings-list .item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #262b3f;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;
}

.meetings-tabs .tab-content .meetings-list .item .item-info h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 6px;
  color: #ffffff;
}

.meetings-tabs .tab-content .meetings-list .item .item-info .date,
.meetings-tabs .tab-content .meetings-list .item .item-info .time {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 140%;
  margin-bottom: 6px;
  color: #a4abc5;
}

.meetings-list {
  height: 400px;
  overflow-y: scroll;
}

.meetings-tabs .tab-content .meetings-list .item .item-info .date svg,
.meetings-tabs .tab-content .meetings-list .item .item-info .time svg {
  margin-right: 8px;
}

.meetings-tabs .tab-content .meetings-list .item .item-avatar img {
  width: 30px;
}

.no-data-dis-none {
  display: none;
}

#no-data {
  color: white;
}

#details {
  font-style: normal;
  font-weight: normal;

  font-size: 10px;
  line-height: 140%;
  margin-bottom: 6px;
  color: #a4abc5;
}

.meeting_links {
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
}

.item .item-links {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.flex-start {
  justify-content: flex-start !important;
}

.lead-action-btn:disabled {
  opacity: 0.5;
}

.item .item-links a,
.lead-action-btn {
  height: 33px;
  width: 32%;
  align-items: center;
  justify-content: center;
  border: 1px solid #a4abc4;
  border-radius: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  transition: 0.3s;
  color: #ffffff;
}
.lead-action-btn:hover {
  background-color: #12c197;
  border-color: #12c197;
}
.item .item-links a:hover {
  transition: 0.3s;
  background-color: #009fad;
  border-color: #009fad;
}

/*Meeting details*/

.meeting-details {
  width: 360px;
  background-color: #171b26;
}

.m-d-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #262b3f;
  padding: 12px 15px;
  margin-bottom: 25px;
}

.m-d-info {
  padding: 0 15px;
  margin-bottom: 140px;
}

.m-d-top h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.04em;
  margin: 0;
  color: #ffffff;
}

.m-d-info h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.m-d-info h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #a4abc4;
}

.m-d-info .name {
  margin-bottom: 20px;
}

.m-d-info .name h3 {
  margin-bottom: 12px;
}

.m-d-info .list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.m-d-info .list .item {
  width: 31%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
}

.m-d-info .list .item h3 {
  margin-bottom: 14px;
}

.m-d-info .description {
  margin-bottom: 25px;
}

.m-d-info .description h3 {
  margin-bottom: 10px;
}

.m-d-info .description p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 0;
  color: #ffffff;
}

.m-d-info-item .flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.m-d-info-item h3 {
  margin-bottom: 9px;
}

.m-d-info-item img,
.m-d-info-item svg {
  width: 30px;
  margin-right: 12px;
}

.m-d-info-item .name,
.m-d-info-item a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 0;
  color: #ffffff;
}

.m-d-links {
  padding: 0 15px 15px 15px;
}

.m-d-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 43px;
  margin-bottom: 12px;
  border: 2px solid #656b82;
  border-radius: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  transition: 0.3s;
  color: #ffffff;
}

.m-d-links a:hover {
  transition: 0.3s;
  background-color: #009fad;
  border-color: #009fad;
}

/*Meeting schedule*/

.meeting-schedule {
  width: 360px;
  background-color: #171b26;
}

.m-s-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #262b3f;
  padding: 12px 15px;
  margin-bottom: 25px;
  position: sticky;
  top: 0px;
  z-index: 9;
}

.m-s-top h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.04em;
  margin: 0;
  color: #ffffff;
}

.m-s-form {
  padding: 0 15px 15px 15px;
}

.m-s-form form label {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  margin-bottom: 12px;
  color: #a4abc4;
}

.m-s-form form label input,
.m-s-form form label select {
  width: 100%;
  height: 40px;
  border: 1px solid #656b82;
  border-radius: 4px;
  margin-top: 4px;
  background-color: transparent;
  outline: none;
  appearance: none;
  padding: 0 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
}

.m-s-form form label input::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
}

.m-s-form form label select {
  background-image: url("../images/icon-select.svg");
  background-repeat: no-repeat;
  background-position: 95%;
}

.m-s-form .two-labels {
  display: flex;
  justify-content: space-between;
}

.m-s-form .two-labels label {
  width: 48%;
}

.m-s-form .two-labels label input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.m-s-form .add-members {
  margin-top: 6px;
}

.m-s-form .add-members a {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #009fad;
}

.m-s-form .add-members a {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #009fad;
}

.m-s-form .add-members a svg {
  margin-right: 10px;
}

.m-s-form form label textarea {
  width: 100%;
  min-height: 80px;
  border: 1px solid #656b82;
  border-radius: 4px;
  margin-top: 4px;
  background-color: transparent;
  outline: none;
  appearance: none;
  resize: none;
  padding: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
}

.m-s-form form label textarea::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
}

.m-s-form .durations {
  display: flex;
  justify-content: space-between;
}

.m-s-form .durations label {
  width: 62px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #656b82;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  cursor: pointer;
  color: #ffffff;
}

.m-s-form .durations input {
  display: none;
}

.m-s-form .durations input:checked + label {
  background-color: #009fad;
}

.m-s-form .actions {
  margin-top: 100px;
}

.m-s-form .actions .btn-main {
  margin-bottom: 12px;
}

.m-s-form .actions .cancel {
  border: 2px solid #656b82;
  background-color: transparent;
}

/*Settings*/

.settings-page {
  width: 360px;
  background-color: #171b26;
}

.settings-top {
  display: flex;
  align-items: center;
  background-color: #262b3f;
  padding: 12px 15px;
  margin-bottom: 20px;
}

.settings-top h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 200%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: 0;
  color: #ffffff;
}

.settings-list h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 200%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: 0;
  color: #ffffff;
}

.settings-list {
  padding: 0 15px;
}

.settings-list .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.settings-list .item.notifications {
  margin-bottom: 40px;
}

.settings-list .item .custom-switch {
  width: 34px;
  height: 14px;
  min-height: auto;
  padding-left: 0;
  position: relative;
}

.settings-list .item .custom-control-label::before {
  width: 34px;
  height: 14px;
  background-color: #a4abc4;
  border-radius: 20px;
  left: 0;
  top: 0;
  box-shadow: none;
  border: 0;
}

.settings-list
  .item
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #009fad;
}

.settings-list .item .custom-control-label::after {
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  left: -2px;
  top: -3px;
  border-radius: 50%;
}

.settings-list
  .item
  .custom-control-input:checked
  ~ .custom-control-label::after {
  left: 4px;
}

.settings-list .item.hours {
  margin-bottom: 24px;
}

.settings-list .item.hours h2 {
  margin-bottom: 14px;
}

.settings-list .item .hours-list {
  width: 100%;
}

.settings-list .item .hours-list h3 {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  margin: 0;
  color: #ffffff;
}

.settings-list .item .hours-list h3 svg {
  margin-right: 15px;
  width: 20px;
}

.settings-list .item .hours-list .hours-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.settings-list .item .hours-list .hours-list-item a {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #a4abc5;
}

.settings-list .item .hours-list .hours-list-item a svg {
  margin-left: 17px;
  height: 15px;
}

.settings-list .item.schedule h2 {
  width: 100%;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
}

.heroicon {
  width: 16px;
  height: 16px;
  color: #ffffff;
  margin-right: 10px;
}

.settings-list .item .schedule-list {
  width: 100%;
}

.settings-list .item .schedule-list-item {
  display: block;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

.settings-list .item .schedule-list-item h3 {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
}

.settings-list .item .schedule-list-item h3 svg {
  margin-right: 15px;
  width: 20px;
}

.settings-list .item .schedule-list-item a {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #a4abc5;
}

.settings-list .item .schedule-list-item a svg {
  margin-left: 17px;
  height: 15px;
}

/*Notifications*/

.notifications-page {
  width: 360px;
  background-color: #171b26;
}

.notifications-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #262b3f;
  padding: 12px 15px;
}

.notifications-top h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 200%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: 0;
  color: #ffffff;
}

.notifications-top .close-notifications {
  margin-left: 22px;
}

.notifications-tabs {
  margin-bottom: 25px;
}

.notifications-tabs .nav {
  background: #262b3f;
  border-bottom: 0;
  margin-bottom: 20px;
}

.notifications-tabs .nav .nav-item {
  width: 33.3%;
}

.notifications-tabs .nav .nav-item .nav-link {
  padding: 12px 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 200%;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  border: 0;
  color: #a4abc4;
}

.notifications-tabs .nav .nav-item .nav-link:hover {
  border: 0;
}

.notifications-tabs .nav .nav-item .nav-link.active {
  background-color: transparent;
  color: white;
  border-bottom: 3px solid #009fad;
}

.notifications-list {
  padding: 0 15px 25px 15px;
}

.notifications-list .item {
  display: flex;
  background-color: #262b3f;
  border: 1px solid #262b3f;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.notifications-list .item:hover,
.notifications-list .item.active {
  transition: 0.3s;
  border-color: #009fad;
}

.notifications-list .item .avatar {
  margin-right: 10px;
}

.notifications-list .item .avatar img {
  width: 48px;
}

.notifications-list .item .info p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  margin-bottom: 4px;
  color: #ffffff;
}

.notifications-list .item .info .time {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 140%;
  color: #a4abc5;
}

/*Incoming*/

.incoming {
  width: 360px;
  background-color: #171b26;
}

.incoming-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #262b3f;
  padding: 12px 15px;
}

.incoming-top .flex {
  display: flex;
  align-items: center;
}

.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #009fad;
  border-radius: 15px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 100%;
  padding: 4px 8px;
  color: #ffffff;
}

.incoming-top h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.04em;
  margin: 0;
  color: #ffffff;
}

.incoming-top .close-incoming {
  margin-left: 14px;
}

.incoming-screens {
  display: flex;
}

.incoming-screens .item {
  position: relative;
  width: 50%;
  background: linear-gradient(0deg, #656b82, #656b82);
  border: 2px solid #171b26;
}

.incoming-screens .item.active {
  border-color: #ef6e6e;
}

.incoming-screens .item img {
  width: 100%;
  height: 140px;
  object-fit: cover;
}

.incoming-screens .item .item-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
}

.incoming-screens .item .item-bottom .name {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;
}

.incoming-screens .item .item-bottom .status {
  border: 1px solid #ffffff;
  border-radius: 3px;
  padding: 1px;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 103%;
  text-transform: uppercase;
  color: #ffffff;
}

.incoming-screens .item .item-bottom .time {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 103%;
  text-transform: uppercase;
  color: #ffffff;
}

.incoming-screens .item .item-bottom .time svg {
  margin-right: 3px;
}

.incoming-actions {
  background-color: #262b3f;
  padding: 22px 15px 14px 15px;
  margin-bottom: 22px;
  display: flex;
  justify-content: center;
}

.i-d-actions {
  margin-bottom: 0;
}

.incoming-actions .flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.incoming-actions .flex .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background-color: #262b3f;
  border: 1px solid #171b26;
  border-radius: 50%;
  transition: 0.3s;
  margin: 0 2.5px;
}

.incoming-actions .flex .item.active {
  background-color: #009fad;
}

.incoming-actions .flex .item.active svg path {
  fill: #fff;
}

.incoming-actions .flex svg path {
  transition: 0.3s;
  fill: #a4abc4;
}

.incoming-actions .flex .item:hover {
  transition: 0.3s;
  background-color: #009fad;
}

.incoming-actions .flex .item:hover svg path {
  fill: #fff;
}

.incoming-actions .flex .decline-call svg {
  height: 48px;
}

.incoming-actions .flex .decline-call svg path {
  fill: white;
}

.incoming-actions .accept-call {
  min-width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #12c197 0%, #009fad 100%);
  border: 2px solid #171b26;
  border-radius: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
}

.incoming-actions .accept-call svg {
  margin-right: 4px;
}

.incoming-actions .decline-call svg {
  height: 40px;
}

.incoming-list {
  padding: 0 15px 15px 15px;
}

.incoming-list .item {
  padding: 12px;
  background-color: #262b3f;
  border-radius: 8px;
  margin-bottom: 12px;
}

.incoming-list .item h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: #ffffff;
}

.incoming-list .item ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.incoming-list .item ul li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.incoming-list .item .title {
  width: 100px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  margin-right: 10px;
  color: #a4abc4;
}

.incoming-list .item .text,
.incoming-list .item .text a {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
}

#lead-main-configurations {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 10px;
}

#lead-main-configurations .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#lead-main-configurations select {
  border-radius: 5px;
  width: 105px;
}

#lead-main-configurations label {
  color: #ffffff;
}

.leads-list-icon {
  color: #656c82;
  width: 16px !important;
  height: 16px !important;
}

.lead_li_id {
display: flex;
flex: 0 0 auto;
}

.incoming-list .item .text a {
  display: flex;
  align-items: center;
}

.incoming-list .item .text a svg {
  margin-right: 8px;
}

/*Incoming details*/

.i-d-tabs .nav {
  background: #262b3f;
  border-bottom: 0;
  margin-bottom: 20px;
}

.i-d-tabs .nav .nav-item {
  width: 33.3%;
}

.i-d-tabs .nav .nav-item .nav-link {
  padding: 12px 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 200%;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  border: 0;
  color: #a4abc4;
}

.i-d-tabs .nav .nav-item .nav-link:hover {
  border: 0;
}

.i-d-tabs .nav .nav-item .nav-link.active {
  background-color: transparent;
  color: white;
  border-bottom: 3px solid #009fad;
}

.incoming-chat .chat-wrapper {
  min-height: 500px;
  padding: 0 15px 15px 15px;
}

#main {
  background-color: #262b3f;
  min-height: 100vh;
}

.incoming-chat .item {
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.incoming-chat .item .message {
  max-width: 75%;
  width: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 7px;
  padding: 10px 12px;
  text-align: left;
  color: #ffffff;
}

.incoming-chat .receive-message .message {
  background-color: #009fad;
  border-radius: 16px 16px 16px 0;
}

.incoming-chat .sent-message {
  align-items: flex-end;
}

.incoming-chat .sent-message .message {
  background-color: #262b3f;
  border-radius: 16px 16px 0 16px;
}

.incoming-chat .item .time {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 140%;
  color: #a4abc5;
}

.chat-type {
  display: flex;
  align-items: center;
  background-color: #262b3f;
  padding: 13px 15px;
}

.chat-type button {
  background-color: transparent;
  border: 0;
  outline: none;
}

.chat-type .file-attach label {
  cursor: pointer;
  margin-bottom: 0;
}

.chat-type .file-attach input {
  display: none;
}

.chat-type input[type="text"] {
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: none;
  appearance: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  margin: 0 22px;
  color: #a4abc4;
}

.chat-type input[type="text"]::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #a4abc4;
}

.chat-type input[type="submit"] {
  width: 20px;
  height: 18px;
  background-color: transparent;
  border: 0;
  outline: none;
  appearance: none;
  background-image: url("../images/icon-send.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.incoming-details {
  padding: 0 15px 15px 15px;
}

.incoming-details .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.incoming-details .item .avatar {
  width: 75px;
}

.incoming-details .item .avatar img {
  width: 100%;
}

.incoming-details .item label {
  width: 100px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #a4abc5;
}

.incoming-details .item .fields {
  width: 220px;
}

.incoming-details .item input,
.incoming-details .item select {
  height: 32px;
  width: 100%;
  max-width: 220px;
  border: 1px solid #656b82;
  border-radius: 4px;
  background-color: transparent;
  outline: none;
  appearance: none;
  padding: 0 12px;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
}

.incoming-details .item select {
  background-image: url("../images/icon-select.svg");
  background-repeat: no-repeat;
  background-position: 95%;
}

.incoming-details .item textarea {
  min-height: 60px;
  width: 100%;
  max-width: 100%;
  border: 1px solid #656b82;
  border-radius: 4px;
  background-color: transparent;
  outline: none;
  appearance: none;
  resize: none;
  padding: 12px;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
}

.incoming-details .actions {
  width: 100%;
}

.incoming-details .actions .btn-main {
  margin-bottom: 12px;
}

.incoming-details .actions .btn-main.add-new-contact {
  margin-bottom: 12px;
  border: 1px solid #009fad;
  background-color: transparent;
  color: #009fad;
}

.incoming-details .actions .btn-main.add-new-contact svg {
  margin-right: 5px;
}

.send-sms {
  width: 360px;
  background-color: #171b26;
}

.sms-form {
  padding: 0 15px;
}

.sms-form label {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 12px;
  color: #a4abc4;
}

.sms-form label textarea {
  width: 100%;
  min-height: 115px;
  margin-top: 4px;
  border: 1px solid #656b82;
  border-radius: 4px;
  outline: none;
  appearance: none;
  resize: none;
  background-color: transparent;
  padding: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
}

/*Call transfer*/

.call-tansfer {
  width: 360px;
  background-color: #171b26;
}

.call-tansfer .m-s-top {
  margin-bottom: 0;
}

.c-t-tabs .nav {
  background: #262b3f;
  border-bottom: 0;
  margin-bottom: 20px;
}

.c-t-tabs .nav .nav-item {
  width: 50%;
}

.c-t-tabs .nav .nav-item .nav-link {
  padding: 12px 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 200%;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  border: 0;
  color: #a4abc4;
}

.c-t-tabs .nav .nav-item .nav-link:hover {
  border: 0;
}

.c-t-tabs .nav .nav-item .nav-link.active {
  background-color: transparent;
  color: white;
  border-bottom: 3px solid #009fad;
}

.members-list {
  padding: 0 15px;
}

.members-list .search {
  width: 100%;
  margin-bottom: 20px;
}

.members-list .search input {
  width: 100%;
  height: 32px;
  background-color: #262b3f;
  background-image: url("../images/icon-search.svg");
  background-repeat: no-repeat;
  background-position: 5%;
  border-radius: 20px;
  padding-left: 44px;
  outline: none;
  appearance: none;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 200%;
  color: #a4abc4;
}

.members-list .search input::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 200%;
  color: #a4abc4;
}

.members-list .wrapper {
  margin-bottom: 12px;
}

.members-list .wrapper .item {
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  border: 2px solid transparent;
  border-radius: 4px;
}

.members-list .wrapper .item .avatar {
  width: 48px;
  margin-right: 14px;
}

.members-list .wrapper .item .avatar img {
  width: 100%;
}

.members-list .wrapper .item .info h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 3px;
  color: #ffffff;
}

.members-list .wrapper .item .info .availability {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #a4abc4;
}

.members-list .wrapper .actions .btn-main {
  margin-bottom: 12px;
}

.members-list .wrapper .actions .btn-main.cancel {
  background-color: transparent;
  border: 1px solid #a4abc4;
}

/*Call summary*/

.call-summary {
  width: 360px;
  background-color: #171b26;
  padding-bottom: 130px;
}

.c-s-user {
  padding: 0 15px;
  margin-bottom: 15px;
  text-align: center;
}

.c-s-user .flex {
  border-bottom: 1px solid #444858;
  padding-bottom: 15px;
}

.c-s-user .avatar {
  width: 60px;
  margin: 0 auto 8px auto;
}

.c-s-user .avatar img {
  width: 100%;
}

.c-s-user h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #ffffff;
}

.c-s-user .phone a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #a4abc4;
}

.c-s-user .phone a svg {
  margin-right: 6px;
}

.c-s-form {
  padding: 0 15px 15px 15px;
}

.c-s-form .item {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-s-form .item label {
  width: 100px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  color: #a4abc5;
}

.c-s-form .item .stars {
  width: 220px;
  margin-bottom: 12px;
}

.c-s-form .item input,
.c-s-form .item select {
  height: 32px;
  width: 220px;
  border: 1px solid #656b82;
  border-radius: 4px;
  background-color: transparent;
  outline: none;
  appearance: none;
  padding: 0 12px;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
}

.c-s-form .item select {
  background-image: url("../images/icon-select.svg");
  background-repeat: no-repeat;
  background-position: 95%;
}

.c-s-form .item textarea {
  min-height: 60px;
  width: 100%;
  max-width: 100%;
  border: 1px solid #656b82;
  border-radius: 4px;
  background-color: transparent;
  outline: none;
  appearance: none;
  resize: none;
  padding: 12px;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
}

.c-s-form .actions {
  width: 100%;
}

.c-s-form .actions .btn-main {
  margin-bottom: 12px;
}

.c-s-form .actions .btn-main.add-new-contact {
  margin-bottom: 12px;
  border: 1px solid #009fad;
  background-color: transparent;
  color: #009fad;
}

.c-s-form .actions .btn-main.add-new-contact svg {
  margin-right: 5px;
}

.f-s-video {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  position: relative;
  padding: 27px 15px 5px 15px;
}

.f-s-video .video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.f-s-video .video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.f-s-video .incoming {
  position: relative;
  width: 302px;
  background: rgba(23, 27, 38, 0.7);
  border-radius: 8px;
}

.f-s-video .incoming .close {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 15px 15px 0 15px;
  text-align: right;
  opacity: 1;
  float: none;
  text-shadow: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  margin-bottom: 0;
  color: #ffffff;
}

.f-s-video .incoming .close {
  opacity: 1;
}

.f-s-video .incoming .close:not(:disabled):not(.disabled):focus,
.f-s-video .incoming .close:not(:disabled):not(.disabled):hover {
  opacity: 1;
}

.f-s-video .incoming .close h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  margin-bottom: 0;
  color: #ffffff;
}

.f-s-video .incoming .close-title {
  justify-content: space-between;
  margin-bottom: 12px;
}

.f-s-video .incoming .nav {
  background-color: transparent;
  border-radius: 8px 8px 0 0;
  margin-bottom: 5px;
  padding: 0 15px 15px 15px;
}

.f-s-video .incoming .nav .nav-item {
  width: 50%;
}

.f-s-video .incoming .nav .nav-item .nav-link {
  border-bottom: 3px solid #262b3f;
}

.f-s-video .incoming .nav .nav-item .nav-link.active {
  border-color: #009fad;
}

.f-s-video .incoming .incoming-details .item .fields {
  width: 160px;
}

.f-s-video .incoming .incoming-details .item input,
.f-s-video .incoming .incoming-details .item select {
  max-width: 160px;
}

.f-s-video .incoming .incoming-details .item .avatar {
  width: 80px;
}

.f-s-video .incoming .incoming-list .item {
  background: rgba(38, 43, 63, 0.5);
}

.f-s-video .bottom-bar {
  width: 100%;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  position: relative;
}

.f-s-video .bottom-bar .left,
.f-s-video .bottom-bar .center .actions,
.f-s-video .bottom-bar .right .actions {
  display: flex;
  align-items: center;
}

.f-s-video .bottom-bar .left .avatar {
  width: 48px;
  margin-right: 10px;
}

.f-s-video .bottom-bar .left .avatar img {
  width: 100%;
}

.f-s-video .bottom-bar .left h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  margin: 0 12px 0 0;
  color: #ffffff;
}

.f-s-video .bottom-bar .left .availability {
  padding: 2px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 103%;
  text-transform: uppercase;
  color: #ffffff;
}

.f-s-video .bottom-bar .center .item {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #262b3f;
  border: 1px solid #171b26;
  border-radius: 50%;
  margin: 0 2.5px;
  transition: 0.3s;
}

.f-s-video .bottom-bar .center .decline-call {
  width: 48px;
  height: 48px;
  margin: 0 2.5px;
}

.f-s-video .bottom-bar .center .item svg {
  margin: 0;
}

.f-s-video .bottom-bar .center .item svg path {
  transition: 0.3s;
  fill: #a4abc4;
}

.f-s-video .bottom-bar .center .item:hover,
.f-s-video .bottom-bar .center .item.active {
  transition: 0.3s;
  background-color: #009fad;
}

.f-s-video .bottom-bar .center .item:hover svg path,
.f-s-video .bottom-bar .center .item.active svg path {
  transition: 0.3s;
  fill: white;
}

.f-s-video .bottom-bar .right .item {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  border-radius: 50%;
  margin: 0 2.5px;
  transition: 0.3s;
}

.f-s-video .bottom-bar .right .item:hover,
.f-s-video .bottom-bar .right .item.active {
  transition: 0.3s;
  background-color: white;
}

.f-s-video .bottom-bar .right .item svg {
  margin: 0;
}

.f-s-video .bottom-bar .right .item svg path {
  transition: 0.3s;
  fill: white;
}

.f-s-video .bottom-bar .right .item:hover svg path,
.f-s-video .bottom-bar .right .item.active svg path {
  transition: 0.3s;
  fill: #171b26;
}

.f-s-video .incoming .chat-type {
  background: #171b26;
  border-radius: 8px;
  padding: 9px 11px;
  margin: 15px;
}

.f-s-video .incoming .members-list .actions {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.f-s-video .incoming .members-list .actions .btn-main {
  width: 48%;
}

/*Schedule page*/

.schedule-page {
  width: 720px;
  background-color: #171b26;
}

.user-acc-info-items {
  width: 100px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #a4abc5;
}

.user-acc-info-items-data {
  color: #ccffff;
  text-align: left;
  justify-content: flex-start !important;
  padding-left: 10px;
  font-size: 13px;
  max-width: 50%;
}

.current {
  color: #ffffff !important;
}

#show-onoff-status {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 200%;
}

.remove-day-check-box {
  display: none;
}

.unread {
  display: none;
}
.star-ratings svg {
  height: 17px !important;
  width: 17px !important;
}
.star-ratings {
  display: flex !important;
}
.star-container {
  padding: 0 !important;
}
#message-details-holder.leads-list {
  max-height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
}
.textarea-yellow {
  background-color: #f3e779;
}
.hr-style {
  border-color: rgb(255 255 255 / 60%);
  margin: 1rem 14px;
}
.select-mode select {
  margin: 0 15px;
}
.no-message-wrap {
  text-align: center;
  color: #fff;
  padding: 20px;
  background-color: #262b3f;
  border-radius: 10px;
}
.no-message-wrap img {
  max-width: 100px;
  margin: 0 auto 30px;
}
.call-routing {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 15px;
}

.call-routing h6 {
  margin-bottom: 0;
  color: #fff;
}
.call-routing .custom-switch {
  padding-left: 0;
}
.call-routing .custom-control-label::before {
  width: 44px;
  height: 22px;
  background-color: #a4abc4;
  border-radius: 20px;
  left: 0;
  top: 0;
  box-shadow: none;
  border: 0;
}
.call-routing .custom-control-label::after {
  background-color: #ffffff;
  width: 16px;
  height: 16px;
  left: 4px;
  top: 3px;
}
.call-routing .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #12c197;
}
.call-routing .custom-control-input:checked ~ .custom-control-label::after {
  left: 12px;
}
.call-routing-wrap {
  border-bottom: 1px solid #5c6173;
  margin-bottom: 20px;
}
.call-routing-wrap:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}
.call-routing-wrap p {
  color: #a4abc4;
}
.custom-control-label {
  height: 22px;
  width: 44px;
}
.image-size {
  height: 20px;
  width: 20px;
}
.openModal {
  color: #fff;
  margin: 0;
  padding: 50px 0 0;
  list-style: none;
}
.openModal li a svg {
  margin-right: 20px;
}
.openModal li a {
  cursor: pointer;
  display: flex;
  padding: 10px 20px;
}
.full-screen {
  width: 360px !important;
  height: calc(100vh - 48px);
  margin-top: 48px;
  overflow: visible;
}
.close-icon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
  height: 20px;
  width: 20px;
}
.send-sms-blog span {
  cursor: pointer;
  height: 38px;
  min-width: 38px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
.send-sms-blog img {
  height: 25px;
  width: 25px;
}
.send-sms-blog .send-icon img {
  height: 30px;
  width: 30px;
}
.send-sms-blog textarea {
  resize: none;
  padding: 7px 10px 7px 20px;
  border-radius: 50px;
}
.send-sms-blog {
  /* bottom: 20px; */
  background: #181c26;
  padding: 10px 0;
}
.keypad-dialer svg path {
  stroke: #009fad;
}
.keypad-dialer textarea,
.keypad-dialer input {
  background-color: #1d1e22;
  color: #009fad;
  margin: 20px 0;
  border-radius: 5px;
  font-size: 1.5rem;
  padding: 0 10px;
  border: 2px solid #009fad;
}
.call-summary-modal p {
  margin-top: 5px;
  margin-bottom: 0;
}

.call-summary-modal textarea,
.call-summary-modal input {
  background-color: #1d1e22;
  color: #fff;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 10px;
  height: 40px;
  border: 2px solid #009fad;
  width: 320px;
}
.call-summary-modal textarea {
  height: 80px;
}
.chat-messages {
  padding-bottom: 120px;
}
.text-white-color a,
.text-white-color {
  color: #fff;
}
.name-style {
  height: 80px;
  width: 80px;
  background-color: #cccccc;
  border-radius: 100%;
  text-align: center;
  padding: 20px;
  border: solid 1px blue;
}
.schedule-list {
  justify-content: space-between;
  color: rgb(164, 171, 196);
  display: flex;
  background-color: rgb(38, 43, 63);
  border-radius: 10px;
  padding: 2%;
}
.tab-button button {
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  color: #a4abc4;
  border-bottom: 2px solid rgb(38, 43, 63);
  outline: 0;
}
.tab-button button.active {
  border-bottom: 2px solid #3583cd;
  color: #fff;
}
.tab-schedule h2 {
  font-size: 14px;
  text-transform: uppercase;
}
.no-active-call {
  padding: 20px 0;
  color: #a4abc4;
}
.no-active-call svg,
.no-active-call img {
  height: 30px;
  width: auto;
  margin: 0 auto 10px;
}

.no-active-call svg {
  fill: #a4abc4;
}

.list-schedule {
  font-size: 12px;
}
.logs-list li:last-child {
  border: 0;
}
.logs-list li {
  color: #fff;
  list-style: none;
  padding: 15px;
  border-bottom: 1px solid gray;
}
.logs-list {
  background-color: #191d27;
  border: 1px solid gray;
  border-radius: 10px;
  margin: 10px;
}
.logs-list li .time {
  margin: 0 0 5px;
  text-align: center;
  background-color: #fbfaf4;
  border: 1px solid #f7eeb9;
  padding: 2px 3px;
  border-radius: 4px;
  font-size: 13px;
  color: #000;
  font-weight: 600;
}
.logs-list li h6 {
  font-size: 16px;
  margin-bottom: 5px;
}
.logs-list li .missed {
  color: #000;
  background-color: rgb(255, 196, 196);
  border: 1px solid red;
}
.logs-list li .green {
  color: #000;
  background-color: #048243;
  border: 1px solid rgb(3, 180, 3);
}

.logs-list li .mahogany {
  color: #000;
  background-color: #4a0100;
  border: 1px solid rgb(3, 180, 3);
}

.logs-list li .mahogany {
  color: #000;
  background-color: #048243;
  border: 1px solid rgb(3, 180, 3);
}
.logs-list li .teal {
  color: #000;
  background-color: #32bf84;
  border: 1px solid rgb(3, 180, 3);
}
.logs-list li .blue {
  color: #000;
  background-color: #d8dcd6;
  border: 1px solid rgb(3, 180, 3);
}
.logs-list li .orangey {
  color: #000;
  background-color: #fa4224;
  border: 1px solid rgb(3, 180, 3);
}
.logs-list li .umber {
  color: #000;
  background-color: #a75e09;
  border: 1px solid rgb(3, 180, 3);
}
.logs-list li .pastel-orange {
  color: #000;
  background-color: #ff964f;
  border: 1px solid rgb(3, 180, 3);
}

.logs-list li .answerd {
  color: #000;
  background-color: rgb(208, 255, 208);
  border: 1px solid rgb(3, 180, 3);
}
.logs-list li .voicemail {
  color: #fff;
  background-color: blue;
  border: 1px solid blue;
}

.logs-list li span {
  text-align: center;
  font-size: 13px;
  padding: 2px 3px;
  border-radius: 4px;
}
.logs-list li p {
  margin: 0;
  font-size: 14px;
  margin: 0 0 5px;
  font-weight: 500;
  color: #b6b8bf;
}
.logs-title {
  font-size: 20px;
  padding: 0 10px;
}
.icon-overflow {
  overflow: inherit;
}
.accordion-header button.active {
  background-color: #262b3f;
}
.p-tag {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
.messag-img {
  width: calc(100% - 48px) !important;
}
.bottom-bar span.read-count {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: red;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-50%, -50%);
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom-bar span.read-count.null-count {
  background-color: blue;
  color: #fff;
}
.logs-details,
.item-list-log {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #a4abc4;
  font-size: 12px;
}
.logs-details {
  margin-top: 5px;
  text-transform: capitalize;
}
.user-image-container {
  margin: -5px 0 -10px;
}
.user-image {
  background-image: url("../images/dummy_profile.webp");
  background-size: cover;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 42%;
  margin-bottom: 15px;
}
.name-styles {
  margin-left: 42%;
  margin-bottom: 20px;
}
.rotate-icon {
  height: 28px;
  transform: rotate(134deg);
}
.phone-numbers{
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.phone-numbers svg{
  margin-right: 7px;
}
.phone-numbers a{
  font-size: 13px;
  line-height: 140%;
  color: #ffffff;
}
.nav-message{
  margin-left: 15px;
}
.call-back{
  margin-left: 15px;
}
.bound-switch{
  padding: 0;
}
.bound-switch .custom-control-label:before{
  left: 0;
}
.bound-switch .custom-control-label:after{
  left: 2px;
}
.loader-class{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: #ffffff20;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-class img{
  margin: 0;
  width: auto;
  user-select: none;
  height: 100px;
  width: auto;
}
.accordion-body{
  position: relative;
}
.saved-popup{
  position: absolute;
  top: 32%;
  width: 318px;
  background-color: #25bac9;
  border-radius: 8px;
}
.popup-body{
  max-height: 232px;
  overflow: auto;
}
.popup-lists{
  border: solid #f8f1f1;
  padding: 4px 11px;
  font-size: 14px;
  border-radius: 6px;
}
.call-forwarding  {
  
  display: flex;
    /* align-items: center; */
    justify-content: space-between;
    margin-bottom: 15px;
  }
.call-forwarding  h6 {
  
color: #fff;
  }
 .call-forwarding-wrap{
    border-bottom: 1px solid #5c6173;
      margin-bottom: 20px;
 }
 .call-forwarding-wrap input {
   width: 207px !important;


 }